<template>
    <div>
        <div style="height:1px;background-color:#DCDFE6;margin: 10px 0px;"></div>
        <div style="display:flex;">
            <div style="display:flex;flex-direction:column; justify-content: space-around; border:1px solid #dcdfe6;border-radius: 2px;margin-right: 10px;margin-bottom: 2px; padding: 5px;">
                <el-input style="width:300px;" v-model="searchObj1.serialNumber" @change="getList1(1)" placeholder="请输入序列号">
                    <template #append><el-button type="primary" @click="getList1(1)">搜索</el-button></template>
                </el-input>
                <el-date-picker style="width:300px;" @change="getList1(1)" v-model="searchObj1.testTime" type="date" placeholder="选择日期">
                </el-date-picker>
                <div style="text-align:center">
                    <el-button type="primary" icon="el-icon-printer" @click="getDeviceConfig(0)">打印</el-button>
                    <el-button type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>
                </div>
            </div>
            <div style="width:400px;">
                <ve-table v-loading="loading1" element-loading-text="加载中" element-loading-spinner="el-icon-loading" :columns="columns1" ref="table1" borderY :table-data="tableData1" row-key-field-name="id" :event-custom-option="eventCustomOption1"></ve-table>
                <ve-pagination :total="searchObj1.total" :pageIndex="searchObj1.pageIndex" :pageSize="4" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:1px;" @on-page-number-change="getList1"></ve-pagination>
            </div>
            <div style="flex:1; display:flex;flex-direction:column; justify-content: space-around;border:1px solid #dcdfe6;border-radius: 2px;margin-left: 10px;margin-bottom: 2px; padding: 5px;">
                <el-row :gutter="20">
                    <el-col :span="5" :offset="1" style="height: 40px;  line-height: 40px;">试验编号：</el-col>
                    <el-col :span="6">
                        <el-input v-model.number="shiYanBianHao" @change="updateGroup"></el-input>
                    </el-col>
                    <el-col :span="6" style="height: 40px;line-height: 40px;">道路号：</el-col>
                    <el-col :span="6">
                        <el-input v-model.number="daoLuHao" @change="updateGroup"></el-input>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:5px;">
                    <el-col :span="5" :offset="1" style="height: 40px;  line-height: 40px;">路幅号：</el-col>
                    <el-col :span="6">
                        <el-input v-model.number="luFuHao" @change="updateGroup"></el-input>
                    </el-col>
                    <el-col :span="6" style="height: 40px;line-height: 40px;">极限差值：</el-col>
                    <el-col :span="6">
                        <el-input v-model.number="jiXianChaZhi" @change="updateGroup"></el-input>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:5px;">
                    <el-col :span="5" :offset="1" style="height: 40px;  line-height: 40px;">百米清零</el-col>
                    <el-col :span="6" style="height: 40px;  line-height: 40px;">
                        <el-radio-group v-model="baiMiQingLing" @input="updateGroup">
                            <el-radio :label="true">是</el-radio>
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="6" style="height: 40px;line-height: 40px;">递增递减：</el-col>
                    <el-col :span="6" style="height: 40px;  line-height: 40px;">
                        <el-radio-group v-model="diZengDiJian" @input="updateGroup">
                            <el-radio :label="true">递增</el-radio>
                            <el-radio :label="false">递减</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:5px;">
                    <el-col :span="5" :offset="1" style="height: 40px;  line-height: 40px;">采样间隔：</el-col>
                    <el-col :span="6">
                        <el-input v-model.number="caiYangJianGe" @change="updateGroup"></el-input>
                    </el-col>
                    <el-col :span="6" style="height: 40px;  line-height: 40px;">
                        平整度规定值：
                    </el-col>
                    <el-col :span="6" style="height: 40px;  line-height: 40px;">
                        <el-input v-model="biaoZhunZhi" placeholder="格式：1.1-2.2"></el-input>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="20" style="margin-top:5px;">
          <el-col :span="11" :offset="1" style="height: 40px;  line-height: 40px;text-align: center;">
            <el-button type="primary" icon="el-icon-printer" @click="getDeviceConfig">打印</el-button>
          </el-col>
          <el-col :span="12" style="height: 40px;  line-height: 40px;text-align: center;">
            <el-button type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>
          </el-col>
        </el-row> -->
            </div>
        </div>
        <ve-table v-loading="loading2" element-loading-text="加载中" element-loading-spinner="el-icon-loading" :columns="columns2" ref="table2" borderY :table-data="tableData2" row-key-field-name="id" :editOption="editOption2"></ve-table>
        <ve-pagination :total="searchObj2.total" :pageIndex="searchObj2.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" @on-page-number-change="getList2"></ve-pagination>
        <el-dialog :visible="dialog_Device_Config" :show-close="false">
            <template #title>打印选项</template>
            <table style="width:100%;">
                <tr>
                    <td>日期</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifRiQi"></el-switch>
                    </td>
                    <td>时间</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifShiJian"></el-switch>
                    </td>
                    <td>道路号</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifDaoLuHao"></el-switch>
                    </td>
                    <td>路幅号</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifLuFuHao"></el-switch>
                    </td>
                </tr>
                <tr>
                    <td>里程号</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifLiChengHao"></el-switch>
                    </td>
                    <td>起始桩号</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifQiShiZhuangHao"></el-switch>
                    </td>
                    <td>采样间隔</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifCaiYangJianGe"></el-switch>
                    </td>
                    <td>极限差值</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifJiXianChaZhi"></el-switch>
                    </td>
                </tr>
                <tr>
                    <td>正超差值</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifZhengChaoChaZhi"></el-switch>
                    </td>
                    <td>负超差值</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifFuChaoChaZhi"></el-switch>
                    </td>
                    <td>正向累计值</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifZhengXiangLeiJiZhi"></el-switch>
                    </td>
                    <td>负向累计值</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifFuXiangLeiJiZhi"></el-switch>
                    </td>
                </tr>
                <tr>
                    <td>测试长度</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifCeShiChangDu"></el-switch>
                    </td>
                    <td>平均速度</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifPingJunSuDu"></el-switch>
                    </td>
                    <td>累计面积</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifLeiJiMianJi"></el-switch>
                    </td>
                    <td>平整度</td>
                    <td>
                        <el-switch v-model="model_Device_Config.ifPingZhengDu"></el-switch>
                    </td>
                </tr>
            </table>
            <!-- <img src="../../../assets/images/pzd801/pzd801print.png"/> -->

            <template #footer>
                <el-row type="flex" justify="end">
                    <el-button type="primary" @click="sendToDeviceForPrint">打印</el-button>
                    <el-button @click="dialog_Device_Config = false">取消</el-button>
                </el-row>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="offlineDialog" width="40%">
            <el-result title="提示">
                <template slot="icon">
                    <img src="/images/pzd801/pzd801print.png" />
                    <!-- <el-image src="images/pzd801/pzd801print.png"></el-image> -->
                </template>
                <template #subTitle>
                    <el-alert title="请确认设备Wifi已联机" type="warning" :closable="false">
                    </el-alert>
                </template>
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="offlineDialog = false">关闭</el-button>
                </template>
            </el-result>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
// import moment from "moment";
import zhCN from "vue-easytable/libs/locale/lang/zh-CN.js";
// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";
import { VeLocale } from "vue-easytable";
//导出相关
// import JsonExcel from "vue-json-excel";

VeLocale.use(zhCN);
Vue.use(VueEasytable);
export default {
    data() {
        let that = this;
        return {
            printOneId: 0,
            offlineDialog: false,
            biaoZhunZhi: null,
            dialog_Device_Config: false,
            model_Device_Config: {},
            shiYanBianHao: null,
            daoLuHao: null,
            luFuHao: null,
            jiXianChaZhi: null,
            baiMiQingLing: null,
            diZengDiJian: null,
            caiYangJianGe: null,
            loading1: false,
            columns1: [
                { field: "serialNumber", key: "serialNumber", title: "序列号" },
                { field: "testTime", key: "testTime", title: "试验时间" },
                {
                    field: "",
                    key: "e",
                    title: "操作",
                    renderBodyCell: ({ row }) => {
                        return (
                            <div
                                style="color:#F56C6C;cursor: pointer;"
                                on-click={() =>
                                    that
                                        .$confirm("确定要删除吗？")
                                        .then(function () {
                                            that.axios.post("PZD801/DelGroup", row).then(() => {
                                                that.getList1();
                                            });
                                        })
                                        .catch(function () { })
                                }
                            >
                                删除
                            </div>
                        );
                    },
                },
            ],
            tableData1: [],
            searchObj1: {
                serialNumber: "",
                testTime: null,
                pageIndex: 1,
                pageSize: 4,
                total: 0,
            },
            selectRow1: null,
            eventCustomOption1: {
                bodyRowEvents: ({ row }) => {
                    return {
                        click() {
                            that.selectRow1 = row;
                            that.getList2();
                        },
                    };
                },
            },
            editOption2: {
                beforeCellValueChange: ({ row, column, changeValue }) => {
                    let zhengShu = /^-?\d+$/;
                    let xiaoShu1 = /^[+-]?\d+(?:\.\d{1})?$/;
                    let xiaoShu2 = /^[+-]?\d+(?:\.\d{2})?$/;

                    let notChanged = true;
                    if (column.field == "liChengHao") {
                        notChanged = row.liChengHao == changeValue;
                        if (zhengShu.test(changeValue)) {
                            row.liChengHao = parseInt(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "qiShiZhuangHao") {
                        notChanged = row.qiShiZhuangHao == changeValue;
                        if (zhengShu.test(changeValue)) {
                            row.qiShiZhuangHao = parseInt(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "ceShiChangDu") {
                        notChanged = row.ceShiChangDu == changeValue;
                        if (zhengShu.test(changeValue)) {
                            row.ceShiChangDu = parseInt(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "zhengChaoChaZhi") {
                        notChanged = row.zhengChaoChaZhi == changeValue;
                        if (zhengShu.test(changeValue)) {
                            row.zhengChaoChaZhi = parseInt(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "fuChaoChaZhi") {
                        notChanged = row.fuChaoChaZhi == changeValue;
                        if (zhengShu.test(changeValue)) {
                            row.fuChaoChaZhi = parseInt(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "zhengXiangLeiJiZhi") {
                        notChanged = row.zhengXiangLeiJiZhi == changeValue;
                        if (xiaoShu2.test(changeValue)) {
                            row.zhengXiangLeiJiZhi = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "fuXiangLeiJiZhi") {
                        notChanged = row.fuXiangLeiJiZhi == changeValue;
                        if (xiaoShu2.test(changeValue)) {
                            row.fuXiangLeiJiZhi = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "pingJunSuDu") {
                        notChanged = row.pingJunSuDu == changeValue;
                        if (zhengShu.test(changeValue)) {
                            row.pingJunSuDu = parseInt(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "leiJiMianJi") {
                        notChanged = row.leiJiMianJi == changeValue;
                        if (xiaoShu1.test(changeValue)) {
                            row.leiJiMianJi = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "pingZhengDu") {
                        notChanged = row.pingZhengDu == changeValue;
                        if (xiaoShu2.test(changeValue)) {
                            row.pingZhengDu = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: "数字格式不正确" });
                            return false;
                        }
                    } else if (column.field == "startTime") {
                        notChanged = row.dataTime == changeValue;
                        let reDateTime = /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
                        if (reDateTime.test(changeValue)) {
                            row.startTime = changeValue;
                        } else {
                            that.$message({ type: "error", message: "时间格式不正确" });
                            return false;
                        }
                    }
                    if (!notChanged) {
                        that.axios.post("PZD801/UpdatePZD801_Result", row).then((response) => {
                            if (response.data.code == 101) {
                                that.$message({ type: "success", message: "保存成功" });
                            } else {
                                that.$message({ type: "error", message: "保存失败" });
                            }
                        });
                    }
                },
                afterCellValueChange: ({ row, column, changeValue }) => {
                    if (column.field == "liChengHao") {
                        row.liChengHao = parseInt(changeValue);
                    } else if (column.field == "qiShiZhuangHao") {
                        row.qiShiZhuangHao = parseInt(changeValue);
                    } else if (column.field == "ceShiChangDu") {
                        row.ceShiChangDu = parseInt(changeValue);
                    } else if (column.field == "zhengChaoChaZhi") {
                        row.zhengChaoChaZhi = parseInt(changeValue);
                    } else if (column.field == "fuChaoChaZhi") {
                        row.fuChaoChaZhi = parseInt(changeValue);
                    } else if (column.field == "zhengXiangLeiJiZhi") {
                        row.zhengXiangLeiJiZhi = parseFloat(changeValue);
                    } else if (column.field == "fuXiangLeiJiZhi") {
                        row.fuXiangLeiJiZhi = parseFloat(changeValue);
                    } else if (column.field == "pingJunSuDu") {
                        row.pingJunSuDu = parseInt(changeValue);
                    } else if (column.field == "leiJiMianJi") {
                        row.leiJiMianJi = parseFloat(changeValue);
                    } else if (column.field == "pingZhengDu") {
                        row.pingZhengDu = parseFloat(changeValue);
                    }
                },
            },
            loading2: false,
            columns2: [
                { field: "startTime", key: "startTime", title: "开始时间", edit: true },
                { field: "liChengHao", key: "liChengHao", title: "里程号", edit: true },
                { field: "qiShiZhuangHao", key: "qiShiZhuangHao", title: "起始桩号", edit: true },
                { field: "ceShiChangDu", key: "ceShiChangDu", title: "测试长度", edit: true },
                { field: "zhengChaoChaZhi", key: "zhengChaoChaZhi", title: "正超差值", edit: true },
                { field: "fuChaoChaZhi", key: "fuChaoChaZhi", title: "负超差值", edit: true },
                { field: "zhengXiangLeiJiZhi", key: "zhengXiangLeiJiZhi", title: "正向累计值", edit: true },
                { field: "fuXiangLeiJiZhi", key: "fuXiangLeiJiZhi", title: "负向累计值", edit: true },
                { field: "pingJunSuDu", key: "pingJunSuDu", title: "平均速度", edit: true },
                { field: "leiJiMianJi", key: "leiJiMianJi", title: "累计面积", edit: true },
                { field: "pingZhengDu", key: "pingZhengDu", title: "平整度", edit: true },
                {
                    field: "",
                    key: "e",
                    title: "操作",
                    renderBodyCell: ({ row, rowIndex }) => {
                        return (
                            <div style="display:flex;justify-content:space-around;">
                                <div
                                    style="color:#409EFF;cursor: pointer;"
                                    on-click={() =>
                                        that.axios.post("PZD801/AddPZD801_Result", row).then((response) => {
                                            if (response.data.code == 101) {
                                                that.searchObj2.total++;
                                                that.tableData2.splice((that.searchObj2.pageIndex - 1) * 10 + rowIndex, 0, response.data.data);
                                            }
                                        })
                                    }
                                >
                                    复制并新增
                                </div>
                                <div
                                    style="color:#409EFF;cursor: pointer;"
                                    on-click={() =>
                                        that.getDeviceConfig(row.id)
                                    }
                                >
                                    打印
                                </div>
                                <div
                                    style="color:#F56C6C;cursor: pointer;"
                                    on-click={() =>
                                        that
                                            .$confirm("确定要删除吗？")
                                            .then(function () {
                                                that.axios.post("PZD801/DelPZD801_Result", row).then(() => {
                                                    that.getList2(that.searchObj2.pageIndex);
                                                });
                                            })
                                            .catch(function () { })
                                    }
                                >
                                    删除
                                </div>
                            </div >
                        );
                    },
                },
            ],
            tableData2: [],
            searchObj2: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                serialNumber: null,
                testTime: null,
            },
        };
    },
    methods: {
        getList1(v) {
            let that = this;
            that.loading1 = true;
            if (v) {
                that.searchObj1.pageIndex = v;
            }
            that.axios.post("PZD801/GetGroupList", that.searchObj1).then((response) => {
                that.tableData1 = response.data.data.data;
                that.searchObj1.total = response.data.data.total;
                if (that.tableData1.length > 0) {
                    that.$refs["table1"].setHighlightRow({
                        rowKey: that.tableData1[0].id,
                    });
                    that.selectRow1 = that.tableData1[0];
                    that.searchObj2.pageIndex = 1;
                    that.getList2();
                }
                that.loading1 = false;
            });
        },
        getList2(v) {
            let that = this;
            if (that.selectRow1) {
                that.loading2 = true;
                that.searchObj2.testTime = that.selectRow1.testTime;
                that.searchObj2.serialNumber = that.selectRow1.serialNumber;
                if (v) {
                    that.searchObj2.pageIndex = v;
                } else {
                    that.searchObj2.pageIndex = 1;
                }
                that.axios.post("PZD801/GetList", that.searchObj2).then((response) => {
                    that.tableData2 = response.data.data.data;
                    that.searchObj2.total = response.data.data.total;
                    if (that.tableData2.length > 0) {
                        that.shiYanBianHao = that.tableData2[0].shiYanBianHao;
                        that.daoLuHao = that.tableData2[0].daoLuHao;
                        that.luFuHao = that.tableData2[0].luFuHao;
                        that.jiXianChaZhi = that.tableData2[0].jiXianChaZhi;
                        that.baiMiQingLing = that.tableData2[0].baiMiQingLing;
                        that.diZengDiJian = that.tableData2[0].diZengDiJian;
                        that.caiYangJianGe = that.tableData2[0].caiYangJianGe;
                    }
                    that.loading2 = false;
                });
            }
        },

        updateGroup() {
            let that = this;
            if (!that.selectRow1) {
                that.$message({ type: "error", message: "请选择记录" });
                return;
            }
            that.axios
                .post("PZD801/UpdateGroup", {
                    serialNumber: that.selectRow1.serialNumber,
                    testTime: that.selectRow1.testTime,
                    shiYanBianHao: that.shiYanBianHao,
                    daoLuHao: that.daoLuHao,
                    luFuHao: that.luFuHao,
                    jiXianChaZhi: that.jiXianChaZhi,
                    baiMiQingLing: that.baiMiQingLing,
                    diZengDiJian: that.diZengDiJian,
                    caiYangJianGe: that.caiYangJianGe,
                })
                .then((response) => {
                    if (response.data.code == 101) {
                        that.$message({ type: "success", message: "保存成功" });
                    } else {
                        that.$message({ type: "error", message: "保存失败" });
                    }
                });
        },
        getDeviceConfig(v) {
            let that = this;
            that.printOneId = v;
            if (!that.selectRow1 || !that.selectRow1.serialNumber) {
                that.$message({ type: "error", message: "请选择记录" });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: "加载配置中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            that.axios.post("PZD801/GetDeviceConfig", { onlyText: that.selectRow1.serialNumber }).then((response) => {
                that.model_Device_Config = response.data.data;
                loading.close();
                that.dialog_Device_Config = true;
            });
        },
        sendToDeviceForPrint() {
            let that = this;
            const loading = this.$loading({
                lock: true,
                text: "发送命令中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            that.axios
                .post("PZD801/printPZD801", {
                    selectRow1: that.selectRow1,
                    pZD801_Device_Config: that.model_Device_Config,
                    printOneId: that.printOneId
                })
                .then((response) => {
                    loading.close();
                    if (response.data.code == 101) {
                        that.$message({ type: "success", message: "发送打印命令成功" });
                    } else if (response.data.code == 201) {
                        that.$message({ type: "error", message: "设备已离线，请检查设备是否开机或网络是否可用" });
                        that.offlineDialog = true;
                    } else if (response.data.code == 401) {
                        that.$message({ type: "error", message: "发送异常" });
                    }
                });
        },
        exportExcel() {
            let that = this;
            if (!that.biaoZhunZhi) {
                that.$message({ type: "error", message: "请输入平整度规定值" });
                return;
            }
            that.selectRow1.biaoZhunZhi = that.biaoZhunZhi;
            that.axios
                .post("PZD801/ExportExcel", that.selectRow1, {
                    responseType: "blob",
                })
                .then((response) => {
                    if (!response) {
                        that.$message({ type: "error", message: "平整度规定值格式错误！" });
                        return;
                    }
                    let arrStr = response.headers["content-disposition"].split(";");
                    let fileName = "data.xlsx";
                    for (let i = 0; i < arrStr.length; i++) {
                        let index = arrStr[i].toUpperCase().indexOf("FILENAME=");
                        if (index > 0) {
                            console.log(arrStr[i]);
                            fileName = arrStr[i].substr(index + 9).replace(/"/g, "");
                            break;
                        }
                    }
                    let b = new Blob([response.data], {
                        type: "application/vnd.ms-excel;charset=utf-8",
                    });
                    let downloadElement = document.createElement("a");
                    let href = window.URL.createObjectURL(b); // 创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = fileName; // 下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); // 点击下载
                    document.body.removeChild(downloadElement); // 下载完成移除元素
                    window.URL.revokeObjectURL(href); // 释放掉blob对象
                });
        },
    },
    mounted() {
        let that = this;
        that.getList1();
        that.getList2();
    },
};
</script>

<style></style>